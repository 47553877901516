import { Empresa } from '../cadastro/empresa.model';
import { CidadesWebServices } from '../endereco.model';
import { Markup } from './markup.model';

export class WebService {
    Id: number;
    CidadesWebServices: CidadesWebServices[];
    Descricao: string;
    Senha?: string;
    PortalSenha?: string;
    Markups: Markup[];
    Sigla: any;
    SiglaInterna: any;
    IdErp: any;
    OpcaoBusca: any;
    PagamentoTerceiros: string;
    Url: any;
    UrlAlternativa: any;
    Login: any;
    Token: any;
    TipoEmpresa: any;
    Producao: any;
    TemSubFornecedor: any;
    Timeout: any;
    ExpireToken: any;
    Aereo: any;
    Hotel: any;
    Ingresso: any;
    Transfer: any;
    Carro: any;
    Seguro: any;
    Financiamento: any;
    Casa: any;
    Pacote: any;
    Evento: any;
    Circuito: any;
    AereoOperacao: boolean;
    Pagamento: any;
    Alteracoes: any;
    TemIRRF: any;
    Nacional: any;
    Internacional: any;
    CodigoPromocao: string;
    Empresas: Empresa[];
    Parametros: ParametroWebService[];
    PortalID: any;
    CodigoCorporativo: any;
    Credencial: any;
    LoginToken: string;
    Fee: any;
    EmailNotificacao: any;
    CiasExcluidas: any;
    StatusMsg?: string;
    StatusCheck?: boolean;
    TaxasExtrasAereo: TaxasExtrasAereo[];
}

export class SubFornecedor {
    Id: number;
    Nome: any;
    Descricao: any;
    IRRF: any;
    Alteracoes: any;
    TemIRRF: any;
    Sigla: any;


}

export class ParametroWebService {
    Id: number;
    Chave: string;
    Valor: string;
    Criptografado: string;
    WebServiceId: number;
    WebService: WebService;
}

export class TaxasExtrasAereo {
    Id: number;
    Cia: string;
    Percentual: number;
    Nacional: boolean;
    Valor: number;
    MoedaId: number;
    WebService: WebService;
    WebServiceId: number;
}
