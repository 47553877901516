import { Agencia } from './../cadastro/agencia.model';
import { PoliticaCircuitoRQ } from './circuito.model';
export class BetoCarrero {
  Id: number;
  Titulo: string;
  CodItemIntegrador: number;
  DescricaoCliente: string;
  EspecificacaoHtml: string;
  DataValidade: string;
  QuantidadeValidadeDias: number;
  Imagem: string;
  CodigoTipoProduto: number;
  DescricaoTipoProduto: string;
  CodigoFamilia: number;
  DescricaoFamilia: string;
  Ingresso: boolean;
  ProdutoCombo: boolean;
  QtdPassaportes: number;
  QtdReplay: number;
  Localizador: number;
  DataEscolhida: string;
  ValorTotalIntegrador: number;
  NumeroItemVenda: number;
  QuantidadeMovimento: number;
  NumeroPassaporte: number;
  DigitoPassaporte: number;
  DataValidadeInicial: string;
  DataValidadeFinal: string;
  WebServiceId: number;
  ValorVenda: number;
  Multiplica: boolean;
  ReservaId: number;
  Status: number;
  StatusDesc: string;
  ValorNet: number;
  MoedaNetId: number;
  MarkupId: number;
  ValorIRRF: number;
  ValorIRRFListagem: number;
  ValorIRRFFechamento: number;
  Taxa: number;
  MoedaListagem: MoedaListagem;
  ValorListagem: number;
  MoedaListagemId: number;
  ValorFechamento: number;
  MoedaFechamentoId: number;
  Erros: any[];
  selecionado: boolean;
  PoliticaCancelamento: any;
  UrlImpressaoPDF: string;
  PrazoCancelamento: any;

  Alteracoes: any = {CriadoEm: ''}

  Adt: number;
  Chd: number;

  Agencia: Agencia;
  AgenciaId: number;
}

export interface MoedaListagem {
  Nome: string;
  Simbolo: string;
  Sigla: string;
  Id: number;
  Erros: any[];
}

export class BuscaBetoCarreroRQ {
  public DataPrevista: Date;
  public EstadosEscolhidos: string[];
  public CodProdutos: number;
  public AgenciaId: number;
  // public QtdAdultos: number;
  // public QtdCriancas: number;
  // public QtdIdosos: number;
}

export class BuscaBetoCarreroRS {
  public IngressosBeto: BetoCarrero[];
  public Mensagem: string[];
}
