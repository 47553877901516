import { Agencia } from '../cadastro/agencia.model';

export class BloqueioAereo {
  public Id: number;
  public Descricao: string;
  public CiaValidadora: string;
  public Tarifa: number;
  public ValorOver: number;
  public MoedaId: number;
  public Markup: any;
  public Taxa: number;
  public ValorFee: number;
  public Bagagem: number;
  public Status: number;
  public Tipo: string;
  public TipoRota: number = -1;
  public EmailPrazo: string;
  public ValorDevolvido: number;
  public AssentosDevolvidos: number;
  public LocalizadorAereo: string;
  public ObservacaoInterna: string;
  public ObservacaoVoucher: string;
  public AeroportoPartida: string;
  public AeroportoChegada: string;
  public DataIn: any;
  public DataOut: any;
  public PrazoEmissaoAte: any;
  public PrazoPagamentoParcial: any;
  public PrazoPagamentoIntegral: any;
  public TotalAssentos: number;
  public Ativo: any;
  public Alteracoes: any;
  public Voos: BloqueioAereoVoo[];
  public AgenciasExclusivas: Agencia[];
  public BloqueioAereoAgenciasExclusivas: any[];
  public Pacote: any;
  public WebService?: any = { Descricao: '' };
  public WebServiceId: number;
  public BloqueioPoliticaVoucher: BloqueioPoliticaVoucher;
  public BloqueioPoliticaVoucherId: number;
  public Disponivel: any;
  public Reservado: any;
  public Emitido: any;
}

export class BloqueioAereoVoo {
  public Id: number;
  public NumeroVoo: number;
  public AeroportoPartida: string;
  public AeroportoChegada: string;
  public DataPartida: any;
  public DataChegada: any;
  public HoraIn: any;
  public HoraOut: any;
  public Cia: string;
  public ClasseTarifaria: string;
  public Equipamento: string;
  public ARUNK: any;
  public BloqueioAereoId: number;
  public Ordem: number;
}

export class BloqueioAereo_Agencia {
  public Agencia?: any = { NomeFantasia: '' };
}

export class BloqueioAereoAgenciaExclusiva {
  public Agencia?: any = { NomeFantasia: '' };
  public BloqueioAereoId: number;
  public AgenciaId: number;
  public PeriodoExclusividadeAgencia: any;
}

export class BloqueioAereo_ConsultaCriterios {
  public Agencia?: any = { NomeFantasia: '' };
}

export class BloqueioPoliticaVoucher {
  public Id: number;
  public IATA: string;
  public Titulo: string;
  public Descricao: string;
}

export class FiltrosBloqueios {
  public Ativo: boolean = null;
  public Exclusivo: boolean = null;
  public CiaValidadora: string = '';
  public Descricao: string = '';
  public DataEmbarqueInicio: any;
  public DataEmbarqueFim: any;
  public Status: number = -1;
  public TipoRota: number = 0;
  public ValorTarifaInicio: number = 0;
  public ValorTarifaFim: number = 10000;
  public Origem: string = '';
  public Destino: string = '';
  public TipoProduto: string = '';
  public QuantidadeDisponivel: number;
  public OrdenarPor: string = 'criacao';
  public OrdenarAsc: boolean = false;
  public Localizador: string = '';
}