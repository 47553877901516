import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { BuscaBetoCarreroRQ } from "../../model/turismo/beto-carrero.model";
import { API } from "../../app.config";

@Injectable()
export class BetoCarreroService {
  constructor(private http: HttpClient) { }

  buscarIngressosBeto(BuscaBetoCarreroRQ: BuscaBetoCarreroRQ): Observable<any> {
    return this.http.post<any>(`${API}/api/BetoCarrero/Busca`, BuscaBetoCarreroRQ, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  reservarIngresso(reservar: any): Observable<any>{
    return this.http.post<any>(`${API}/api/BetoCarrero/Reservar`, reservar, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  buscaPolitica(betoId: number, reservaId: number): Observable<any> {
    return this.http.patch<any>(`${API}/api/BetoCarrero/PoliticaCancelamento/${reservaId}/${betoId}`, null, { headers: new HttpHeaders().set('noloader', 'true') });
  }
  
  cancelarReservaIngresso(betoId: any): Observable<any> {
    return this.http.delete<any>(`${API}/api/BetoCarrero/Cancelar/${betoId}`);
  }
  removerIngresso(betoId: number): Observable<any>{
    return this.http.delete<any>(`${API}/api/BetoCarrero/Delete/${betoId}`);
  }
}
