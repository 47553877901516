import { FiltrosBloqueios } from './../../model/gerenciamento/bloqueioaereo.model';
import { Agencia, BloqueioAereo, BloqueioAereoAgenciaExclusiva } from "../../model";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Router } from "@angular/router";

import { GERENCIAMENTO, ITENSPORPAGINA,API_RELATORIOS } from "../../app.config";
import moment, { Moment } from "moment";

@Injectable()
export class BloqueioAereoService {
   constructor(private http: HttpClient) { }

   buscarPorParametro(param: string, cia: string, pagina: Number, ativo: boolean, exclusivo: boolean): Observable<any> {
      const headers = new HttpHeaders().set("noloader", "t");
      if (param.length > 0 && cia.length > 0) {
         return this.http.get<any>(
            `${GERENCIAMENTO}/api/bloqueioaereo/list/${param.trim()}/${cia.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&Ativo=${ativo}&Exclusivo=${exclusivo}`,
            { headers: headers })
      } else if (param.length > 0) {
         return this.http.get<any>(
            `${GERENCIAMENTO}/api/bloqueioaereo/list/${param.trim()}/null?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&Ativo=${ativo}&Exclusivo=${exclusivo}`,
            { headers: headers })
      } else if (cia.length > 0) {
         return this.http.get<any>(
            `${GERENCIAMENTO}/api/bloqueioaereo/list/null/${cia.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&Ativo=${ativo}&Exclusivo=${exclusivo}`,
            { headers: headers })
      }
      else {
         return this.http.get<any>(
            `${GERENCIAMENTO}/api/bloqueioaereo?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&Ativo=${ativo}&Exclusivo=${exclusivo}`,
            { headers: headers }
         );
      }
   }

   buscarBloqueioAereoFiltrado(FiltrosBloqueios: FiltrosBloqueios, pagina: Number, itensPagina: number = 0): Observable<any> {
      const headers = new HttpHeaders().append('noloader', 't');
      itensPagina = itensPagina == 0 ? ITENSPORPAGINA : itensPagina;

      return this.http.post<any>(`${API_RELATORIOS}/api/bloqueioaereo/list/?Pagina=${pagina}&ItensPorPagina=${itensPagina}`, FiltrosBloqueios, { headers: headers });
   }

   buscarListaCompleta(pagina: Number): Observable<any> {
      return this.http.get<any>(
         `${GERENCIAMENTO}/api/bloqueioaereo/?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`
      );
   }

   buscarListaCompletaSelect(): Observable<any> {
      return this.http.get<any>(
         `${GERENCIAMENTO}/api/bloqueioaereo/?Pagina=1&ItensPorPagina=1000`
      );
   }

   ListaAeroportoPartida(): Observable<any> {
      return this.http.get<any>(
         `${GERENCIAMENTO}/api/bloqueioaereo/aeroportospartida`
      );
   }

   buscarListaAeroportoPartida(): Observable<any> {
      return this.http.get<any>(
         `${GERENCIAMENTO}/api/bloqueioaereo/AeroportosPartidaAtivos`
      );
   }

   buscarListaAeroportoChegada(): Observable<any> {
      return this.http.get<any>(
         `${GERENCIAMENTO}/api/bloqueioaereo/AeroportosChegadaAtivos`
      );
   }

   buscarListaPorParametro(valor: string, de: string, para: string, mesIn: string, anoIn: string, pagina: Number): Observable<any> {
      return this.http.get<any>(
         `${GERENCIAMENTO}/api/bloqueioaereo/listFiltro/${valor}/${de}/${para}/${mesIn}/${anoIn}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`
      );
   }

   buscarListaBloqueiosReservados(DataInicial: string, DataFinal: string, Status: number): Observable<any> {

      const headers = new HttpHeaders().append('noloader', 'noloader');
      let Item = {
         DataInicial: DataInicial,
         DataFinal: DataFinal,
         Status: Status
      }

      return this.http.post<any>(
         `${API_RELATORIOS}/api/bloqueiosaereos/listaBloqueiosAereosReservados`, Item, { headers: headers }
      );
   }

   buscarListaBloqueiosAereos(parametroId: string, AgenciaID, Filtros: string, dataPartida: string, dataChegada: string, origem: string, destino: string, pagina: Number): Observable<any> {
      const headers = new HttpHeaders().append('noloader', 'noloader');
      let FiltrosBloqueios = {
         ID: parametroId,
         AgenciaId: AgenciaID,
         DataInicial: dataPartida,
         DataFinal: dataChegada,
         Origem: origem,
         Destino: destino,
         Filtro: Filtros
      }
      return this.http.post<any>(
         `${GERENCIAMENTO}/api/bloqueioaereo/listBloqueiosAereos?Pagina=${pagina}&ItensPorPagina=${18}`, FiltrosBloqueios, { headers: headers }
      );
   }

   buscarPorId(id: number, ativo?: boolean): Observable<any> {
      if (ativo) {
         return this.http.get<any>(
            `${GERENCIAMENTO}/api/bloqueioaereo/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}&Ativo=${ativo}`
         );
      } else {
         return this.http.get<any>(
            `${GERENCIAMENTO}/api/bloqueioaereo/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`
         );
      }

   }

   listaBloqueiosPagos(id: number): Observable<any> {
      return this.http.get<any>(
         `${GERENCIAMENTO}/api/bloqueioaereo/pagos/${id}`
      );
   }

   remover(id: Number): Observable<any> {
      return this.http.delete<any>(`${GERENCIAMENTO}/api/bloqueioaereo/${id}`);
   }

   salvar(bloqueioAereo: BloqueioAereo): Observable<any> {
      bloqueioAereo.DataIn = moment(bloqueioAereo.DataIn).format("YYYY-MM-DD");
      bloqueioAereo.DataOut = moment(bloqueioAereo.DataOut).format("YYYY-MM-DD");
      bloqueioAereo.PrazoPagamentoParcial = moment(bloqueioAereo.PrazoPagamentoParcial).format("YYYY-MM-DD");
      bloqueioAereo.PrazoPagamentoIntegral = moment(bloqueioAereo.PrazoPagamentoIntegral).format("YYYY-MM-DD");
      bloqueioAereo.PrazoEmissaoAte = moment(bloqueioAereo.PrazoEmissaoAte).format("YYYY-MM-DD");

      if (bloqueioAereo.Id > 0) {
         return this.http.put<any>(
            `${GERENCIAMENTO}/api/bloqueioaereo/${bloqueioAereo.Id}`,
            bloqueioAereo
         );
      } else {
         return this.http.post<any>(
            `${GERENCIAMENTO}/api/bloqueioaereo/`,
            bloqueioAereo
         );
      }
   }

   importarVoos(bloqueioAereo: BloqueioAereo): Observable<any> {
      if (bloqueioAereo.Id > 0) {
         return this.http.post<any>(
            `${GERENCIAMENTO}/api/bloqueioaereo/importar-voos/${bloqueioAereo.Id}`,
            bloqueioAereo
         );
      }
   }

   vinculaAgencia(id: number, agenciaId: number, dataLimite: any = null): Observable<any> {
      console.log(dataLimite)
      if (dataLimite != null) {
         let bloqueioAereoAgenciaExclusiva = { PeriodoExclusividadeAgencia: null, AgenciaId: null, BloqueioAereoId: null, Agencia: null, BloqueioAereo: null };
         bloqueioAereoAgenciaExclusiva.PeriodoExclusividadeAgencia = moment(dataLimite).format("YYYY-MM-DD");
         return this.http.post<any>(`${GERENCIAMENTO}/api/bloqueioaereo-agencia/vincular/${id}/${agenciaId}`, bloqueioAereoAgenciaExclusiva, { headers: new HttpHeaders().set('noloader', 'true') });
      }
      else {
         return this.http.post<any>(`${GERENCIAMENTO}/api/bloqueioaereo-agencia/vincular/${id}/${agenciaId}`, {});
      }
   }

   desvincularAgencia(id: number, agenciaId: number): Observable<any> {
      return this.http.delete<any>(`${GERENCIAMENTO}/api/bloqueioaereo-agencia/desvincular/${id}/${agenciaId}`, {});
   }

  ListaPaxVendidos(dataInicial: any, dataFinal: any, idBloqueio, locBloqueio): Observable<any> {
    return this.http.post<any>(`${API_RELATORIOS}/api/bloqueioaereo/paxvendidos`, { DataInicial: dataInicial, DataFinal: dataFinal, BloqueioId: idBloqueio, LocalizadorBloqueio: locBloqueio }, { headers: new HttpHeaders().set('noloader', 'true') });
  }

   ListaBloqueioGeral(dataInicial: any, dataFinal: any): Observable<any> {
      return this.http.post<any>(`${API_RELATORIOS}/api/bloqueioaereo/listageral`, { DataInicial: dataInicial, DataFinal: dataFinal }, { headers: new HttpHeaders().set('noloader', 'true') });
   }

   ListaBloqueiosReservados(DataInicial: any, DataFinal: any): Observable<any> {
      return this.http.post<any>(`${GERENCIAMENTO}/api/bloqueioaereo/bloqueioReservado`, { DataInicial: DataInicial, DataFinal: DataFinal }, { headers: new HttpHeaders().set('noloader', 'true') });
   }

  buscarListaBloqueiosAereosFRT(parametroId: string, AgenciaID, Filtros: string, dataPartida: string, dataChegada: string, origem: string, destino: string, pagina: Number): Observable<any> {
    const APIFRT = "https://api.comprefacil.tur.br";
    const headers = new HttpHeaders().append('noloader', 'noloader');
    let FiltrosBloqueios = {
      ID: parametroId,
      AgenciaId: 0,
      DataInicial: dataPartida,
      DataFinal: dataChegada,
      Origem: origem,
      Destino: destino,
      Filtro: Filtros,
      Api: true
    }
    return this.http.post<any>(
      `${APIFRT}/api/bloqueioaereo/listBloqueiosAereos?Pagina=${pagina}&ItensPorPagina=${18}`, FiltrosBloqueios, { headers: headers }
    );
  }

  enviarEmailEmissao(bloqueioId: number) {
   const headers = new HttpHeaders().append('noloader', 'noloader');
   return this.http.get<any>(`${GERENCIAMENTO}/api/bloqueioaereo/enviarEmailEmissao/${bloqueioId}`, { headers: headers });
  }
}
