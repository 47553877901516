import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



// import { } from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA, API_RELATORIOS } from '../../app.config';
import { ReservaPagamento } from '../../model';

@Injectable()
export class ContratosService{

  constructor(private http: HttpClient){}

  buscarTodas(): Observable<any>{
    let headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>( `${GERENCIAMENTO}/api/Contrato?Pagina=1&ItensPorPagina=100`, {headers: headers});
  }
  buscarPorParametro(pagina: Number, reservaId : string,status : string,cpfassinatura : string,datainicial: any = "", datafinal: any = "",dataassinaturainicial: any = "", dataassinaturafinal: any = "",tipoPagamento: string, assinado:string): Observable<any>{
    let headers = new HttpHeaders().set('noloader', 't');
    return this.http.get<any>( `${GERENCIAMENTO}/api/contrato?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}&reservaId=${reservaId}&status=${status}&cpfassinatura=${cpfassinatura}&DataInicial=${datainicial}&DataFinal=${datafinal}&DataAssinaturaInicial=${dataassinaturainicial}&DataAssinaturaFinal=${dataassinaturafinal}&TipoPagamento=${tipoPagamento}&Assinado=${assinado}`, {headers: headers});
  }
  alterarStatus(status, contrato, justificativa):Observable<any>{
    return this.http.put<any>(`${GERENCIAMENTO}/api/contrato/${contrato.Id}/aprovar-contrato`, {status: status, justificativa: justificativa});
  }
  buscarPorId(id: number): Observable<any>{
    return this.http.get<any>( `${GERENCIAMENTO}/api/Contrato/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  linkPagamento(pagamentos: any, reservaId: number): Observable<any> {
    return this.http.post<any>(`${GERENCIAMENTO}/api/contrato/linkparapagamento/${reservaId}`, pagamentos);
  }

  RelatorioContratos(DataInicial: any, DataFinal: any, DataAssinaturaInicial: any, DataAssinaturaFinal: any, Status: any): Observable<any> {
      return this.http.post<any>(`${API_RELATORIOS}/api/contrato/relatorio/`, { DataInicial: DataInicial, DataFinal: DataFinal, DataAssinaturaInicial: DataAssinaturaInicial, DataAssinaturaFinal: DataAssinaturaFinal, Status: Status }, { headers: new HttpHeaders().set('noloader', 'true') });
  }

}
