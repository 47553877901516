import { Cidade } from '../endereco.model'


export class Casa {
    public Id : number;
    public Titulo: string;
    public Descricao: string;
    public Quartos: number;
    public Hospedes: number;
    public Banheiros: number;
    public Latitude: string;
    public Longitude: string;
    public Endereco: string;
    public Cidade: Cidade;
    public CidadeId: number;
    public ImagemPrincipal: string;
    public Fornecedor: string;
    public WebService: any;
    public WebServiceId: number;
    public Checkin: any;
    public Checkout: any;
    public Localizador: string;
    public MoedaNet: any;
    public ValorNet: number;
    public ValorVenda: number;
    public MoedaListagem: any;
    public ValorListagem: number;
    public MoedaFechamento: any;
    public ValorFechamento: number;
    public Taxa: number;
    public Multiplica: boolean;
    public ValorIRRF: number;
    public ValorIRRFListagem: number;
    public ValorIRRFFechamento: number;
    public Markup: any;
    public Markdown: any;
    public DataReserva: any;
    public DataCancelamento: any;
    public DataPagamento: any;
    public PrazoCancelamentoWs: any;
    public PoliticaCancelamento: string;
    public MoedaNetId: number;
    public TaxaId: number;
    public MoedaFechamentoId: number;
    public MoedaListagemId: number;
    public MarkupId: number;
    public MarkdownId: number;
    public VoucherKey: string;
    public VoucherValidacao: string;
    public Reserva: any;
    public ReservaId: number;
    public VoucherLogo: string;
    public CodigoCliente: string;
    public Status: any;
    public Deletado: boolean;
    public DeletadoPor: string;
    public DeletadoEm: any;
    public Alteracoes: any;
    public JustificativaAlteracao: string;
}

export class BuscarCasaRQ {
    public FiltroCasa: FiltroCasa;
    public Cidade: Cidade = new Cidade();
    public Checkin: any;
    public Checkout: any;
    public Pessoas: number;
    public Quartos: number;
    public Guid: string;
    public AgenciaId: number;
    public BuscaAssincrona: boolean;
    public BuscaApi: boolean;
}

export class FiltroCasa {
    public ValorMinimo: number;
    public ValorMaximo: number;
    public HotelId: number;
    public Fornecedores: any;
    public Quarto : number = -1;
    public Condominio : string = "";
    public Extras : string[] = [];
    public Inventario : string[] = [];
}
