import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import 'rxjs/add/operator/do';

// import { Usuario } from '../models/usuario'

import { API } from '../app.config';
import { CLIENT_ID } from '../app.config';
import { Usuario } from '../model/usuario.model';
var geolocalizacao: any = false;
@Injectable()
export class LoginService implements OnInit {

  constructor(private http: HttpClient, private router: Router) {
    navigator.geolocation.getCurrentPosition(this.getGeoLocalizacao);
  }

  ngOnInit() {
    navigator.geolocation.getCurrentPosition(this.getGeoLocalizacao);
  }

  today(): Date {
    return new Date();
  }

  user(): any {
    const usuario = JSON.parse(window.localStorage.getItem('loggedUser'));
    const agora = Date.now() / 1000;
    if (usuario === null) {
      this.handleLogin('');
      return null;
    } else
      if (usuario.portal && usuario.portal != window.location.pathname.split('/')[1]) {
        this.logout();
      }
    if (agora < usuario.token_expire) {
      return usuario;
    } else {
      this.logout();
    }
  }

  isLoggedIn() {
    return this.user() != null;
  }

  isLoggedInValidator(): boolean {
    let usuario = this.user();
    if (usuario != null) {
      const agora = Date.now() / 1000;
      console.log(usuario.token_expire);
      console.log(agora);
      console.log(agora < usuario.token_expire);
      return false;
    } else {
      return false;
    }
  }

  handleLogin(caminho: string) {
    // this.router.navigate(['/login', btoa(caminho)])
    let hash: string = window.location.hash;
    if (hash.includes("pt")) {
      this.router.navigate(['/auth/login/pt']);
    }
    else if (hash.includes("en")) {
      this.router.navigate(['/auth/login/en']);
    }
    else if (hash.includes("es")) {
      this.router.navigate(['/auth/login/es']);
    }
    else {
      this.router.navigate(['/auth/login/pt']);
    }
  }

  getGeoLocalizacao(position) {
    geolocalizacao = position;
  }

  logar(username: string, password: string): Observable<any> {

    let headers = new HttpHeaders().set('noloader', 't');
    headers = headers.append('Content-type', 'application/json');
    if (geolocalizacao) {
      headers = headers.append('latitude', geolocalizacao.coords.latitude);
      headers = headers.append('longitude', geolocalizacao.coords.longitude);
    }

    const body = `grant_type=password&username=${username}&password=${password}&client_id=${CLIENT_ID}`;

    return this.http.post<any>(
      `${API}/token`, body, { headers: headers }).do(dados => {
        const data = dados.access_token.toString().split('.');
        const userTemp = JSON.parse(atob(data[1]));
        const usuario = JSON.parse(userTemp.usuario);

        usuario.access_token = dados.access_token;
        usuario.token_expire = (Date.now() / 1000) + dados.expires_in;

        //window.localStorage.setItem('loggedUser', JSON.stringify(usuario));

        this.buscarDadosUsuario(usuario);

      });

  }

  buscarDadosUsuario(usuario) {
    delete usuario.Nome;
    window.localStorage.setItem('loggedUser', JSON.stringify(usuario));
    this.getUserById(usuario.Id).subscribe(
      dados => {
        let user = dados.Items[0];
        delete user.Perfis;
        delete user.Roles;
        delete user.Alteracoes;
        delete user.Cidade.Alteracoes;
        // delete user.Agencia;

        if (window.location.host == 'ark.tur.br' && window.location.pathname.split('/')[1]) {
          user.portal = window.location.pathname.split('/')[1];
        }

        user.access_token = usuario.access_token;
        user.token_expire = usuario.token_expire;
        window.localStorage.setItem('loggedUser', JSON.stringify(user));
      }, erro => {
        console.log("ERRO AO BUSCAR DADOS DO USUARIO");
        console.log(erro);
        this.buscarDadosUsuario(usuario);
      }
    );
  }

  logout() {
    // window.localStorage.removeItem('gy84d8');
    // window.localStorage.removeItem('a5s74');
    // window.localStorage.removeItem('loggedUser');
    window.localStorage.clear();
    window.sessionStorage.clear();
    // this.loggedUser = null;
    this.handleLogin('');
  }

  getUserById(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/usuario/${id}?Pagina=1&ItensPorPagina=1`);
  }

  recuperarSenha(obj: any): Observable<any> {
    return this.http.patch<any>(
      `${API}/api/usuario/recuperarSenha`,
      obj
    );
  }

}
