import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';


import { Financiamento } from '../../model/';

import { TURISMO, ITENSPORPAGINA,API_RELATORIOS } from '../../app.config';

@Injectable()
export class FinanciamentoService {
    constructor(private http: HttpClient) { }


    buscarPorParametro(agenciaId: number, filtro, pagina: Number): Observable<any> {
        const headers = new HttpHeaders().set('noloader', 't');


        return this.http.post<any>(`${TURISMO}/api/Financiamento/list/${agenciaId}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, filtro, { headers: headers });

        //   return this.http.post<any>( `${TURISMO}/api/Financiamento/list/${agenciaId}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});


    }

    buscarPorId(id: number): Observable<any> {
        return this.http.get<any>(`${TURISMO}/api/Financiamento/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    // remover(id: Number): Observable<any>{
    //   return this.http.delete<any>( `${TURISMO}/api/Financiamento/${id}`);
    // }
    //
    salvar(financiamento: Financiamento): Observable<any> {
        if (financiamento.Id > 0) {
            return this.http.put<any>(`${TURISMO}/api/Financiamento/${financiamento.Id}`, financiamento);
        } else {
            return this.http.post<any>(`${TURISMO}/api/Financiamento/`, financiamento);
        }
    }

    enviarProposta(financiamento: Financiamento): Observable<any> {
        return this.http.put<any>(`${TURISMO}/api/financiamento-proposta/enviar/${financiamento.Id}`, financiamento);
    }

    verificarProposta(id: Number): Observable<any> {
        return this.http.get<any>(`${TURISMO}/api/financiamento-proposta/verificar/${id}`);
    }

    verificarBoletos(id: Number): Observable<any> {
        return this.http.get<any>(`${TURISMO}/api/financiamento-proposta/boletos/${id}`);
    }
    buscarFinanciamentoCancelado(): Observable<any> {
    return this.http.post<any>(`${API_RELATORIOS}/api/financiamento/listaCancelados`, { headers: new HttpHeaders().set('noloader', 'true') });
  }
}
