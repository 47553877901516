
export class CacheHotel {
    Id: number;
    Cidade?: any = { Nome: '' };
    CidadeId: number;
    Integrador?: any = { Descricao: '' };
    IntegradorId: number;
    Nome: any;
    Descricao: any;
    CodigoFornecedor: any;
    Markup: any;
    MarkupPacote: any;
    TemIRRF: any;
    Recomendado: any;
    Endereco: any;
    Alteracoes: any;
    Estrelas: any;
    FeeHotel: any;
    Pacote: any;
    CodigoCidadeFornecedor: any;
    SiglaInternaFornecedor: any;
}
